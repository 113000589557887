import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { AuthenticationService } from '../base/authentication.service';
import { switchMap, take } from 'rxjs/operators';
import { Organisation } from '../../interfaces/routering/organisation';

@Injectable()

export class SurveyService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
              private authenticationService: AuthenticationService,
  ) {
  }

  check(id: string): Observable<ServerResponse> {
    return this.authenticationService.organisation$.pipe(
      take(1),
      switchMap((organisation: Organisation): Observable<ServerResponse> => {
        return this.api.get(organisation, this.apiEndpoints.get('reports.survey', {':id': id}));
      })
    );
  }

  submit(id: string, formData: any): Observable<ServerResponse> {
    return this.authenticationService.organisation$.pipe(
      take(1),
      switchMap((organisation: Organisation): Observable<ServerResponse> => {
        return this.api.post(organisation, this.apiEndpoints.get('reports.survey', {':id': id}), formData);
      })
    );
  }
}
