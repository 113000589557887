import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { SurveyService } from '../../services/reports/survey.service';
import { takeUntil } from 'rxjs/operators';
import { fadeInUpAnimation } from '../../../@fury/animations/fade-in-up.animation';
import { AuthenticationService } from '../../services/base/authentication.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'fury-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  animations: [fadeInUpAnimation],
})
export class SurveyComponent implements OnInit, OnDestroy {

  public isLoading: boolean = null;
  public reportUniqueId: string = null;
  public reportNumber: string = null;
  public assetsUrl: string = null;
  public response: 'ok' | 'received' | 'already-received' | 'still_open' = null;
  public message: string = null;

  public form = this.fb.group({
    survey_response: new FormControl(null, Validators.required),
  });

  private destroy$: Subject<null> = new Subject<null>();

  constructor(private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              public authenticationService: AuthenticationService,
              public surveyService: SurveyService,
  ) {
  }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--primary-color', '#1b143c');
    document.documentElement.style.setProperty('--primary-color-text', '#ffffff');

    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params): void => {
      if (typeof params['id'] !== 'undefined') {
        if (params['id'] !== null) {
          this.isLoading = true;
          this.reportUniqueId = params['id'];

          this.getData(params['id']);
        }
      }
      if (typeof params['score'] !== 'undefined') {
        if (params['score'] !== null) {
          this.form.controls.survey_response.setValue(params['score']);
        }
      }
    });
  }

  getData(reportUniqueId: string): void {
    this.surveyService.check(reportUniqueId).subscribe(
      (response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          this.response = 'ok';
          this.reportNumber = response.data.number;
          this.assetsUrl = response.data.assets_url;
        }
        this.isLoading = false;
      },
      (response: ServerResponse): void => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.already_provided !== 'undefined') {
            this.response = 'already-received';
            this.message = response.message;
          } else if (typeof response.data.still_open !== 'undefined') {
            this.response = 'still_open';
            this.message = response.message;
          }
        }
        this.isLoading = false;
      }
    );
  }

  submit(): void {
    this.isLoading = true;

    this.surveyService.submit(this.reportUniqueId, this.form.value).pipe(takeUntil(this.destroy$)).subscribe(
      (response: ServerResponse): void => {
        if (typeof response.message !== 'undefined') {
          this.response = 'received';
          this.message = response.message;
        }
        this.isLoading = false;
      },
      (): boolean => this.isLoading = false
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.isLoading = false;
  }
}
