<fury-alert-box *ngIf="isStaging" class="warn-absolute" style="border-radius:0;margin:0;">
  This is staging environment!
</fury-alert-box>
<fury-alert-box *ngIf="!api.isOnline" class="warn-absolute" style="border-radius:0;margin:0;">
  Uw bent momenteel offline, er is een internet verbinding nodig om met de applicatie te werken.
</fury-alert-box>
<fury-alert-box *ngIf="badConnection" class="warn-absolute" style="border-radius:0;margin:0;">
  Om goed te werken met de applicatie is er goede internet verbinding nodig, controleer uw internet verbinding.
</fury-alert-box>

<div class="container" fxLayout="column">
  <!-- CONTENT -->
  <div [class.scroll-disabled]="scrollDisabled$ | async"
       cdkScrollable
       class="content"
       fxFlex="auto"
       fxLayout="column">

    <div class="content-inner" fxFlex="auto">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- END CONTENT -->
</div>
