import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Organisation } from '../../interfaces/routering/organisation';
import { LocalStorageService } from './local-storage.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { environment } from '../../../environments/environment';
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()

export class AuthenticationService {
  private _organisation: ReplaySubject<Organisation> = new ReplaySubject<Organisation>();
  public organisation$: Observable<Organisation> = this._organisation as Observable<Organisation>;

  constructor(private apiEndpoints: ApiEndpointsService,
              private httpBackend: HttpBackend,
              private localStorageService: LocalStorageService,
  ) {
    this.getOrganisationConfig();

    this.organisation$.subscribe((organisation: Organisation): void => {
      this.updateAppColors(organisation);
    });
  }

  updateAppColors(organisation: Organisation = null): void {
    document.documentElement.style.setProperty('--menu-background-color',
      (organisation !== null && typeof organisation.color_menu_background !== 'undefined' && organisation.color_menu_background !== ''
          ? organisation.color_menu_background
          : '#005192'
      )
    );
    document.documentElement.style.setProperty('--menu-text-color',
      (organisation !== null && typeof organisation.color_menu_text !== 'undefined' && organisation.color_menu_text !== ''
          ? organisation.color_menu_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--primary-color',
      (organisation !== null && typeof organisation.color_primary_action !== 'undefined' && organisation.color_primary_action !== ''
          ? organisation.color_primary_action
          : '#1b143c'
      )
    );
    document.documentElement.style.setProperty('--primary-color-text',
      (organisation !== null && typeof organisation.color_primary_action_text !== 'undefined' && organisation.color_primary_action_text !== ''
          ? organisation.color_primary_action_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--secondary-color',
      (organisation !== null && typeof organisation.color_secondary_action !== 'undefined' && organisation.color_secondary_action !== ''
          ? organisation.color_secondary_action
          : '#666666'
      )
    );
    document.documentElement.style.setProperty('--secondary-color-text',
      (organisation !== null && typeof organisation.color_secondary_action_text !== 'undefined' && organisation.color_secondary_action_text !== ''
          ? organisation.color_secondary_action_text
          : '#ffffff'
      )
    );
  }

  getOrganisationConfig(): void {
    const defaultOrganisation = this.localStorageService.get('default-organisation');

    if (defaultOrganisation !== null) {
      this._organisation.next(<Organisation>defaultOrganisation);
      this.getDefaultOrganisation().then((): void => {
      });
    } else {
      this.getDefaultOrganisation().then(
        (response: Organisation): void => {
          if (typeof response !== 'undefined') {
            this.localStorageService.set('default-organisation', response);
            this._organisation.next(response);
          }
        });
    }
  }

  getDefaultOrganisation(): Promise<Organisation | null> {
    const httpApi: HttpClient = new HttpClient(this.httpBackend);
    return httpApi.get(environment.api_endpoint + this.apiEndpoints.get('public.default-organisation'), {
      observe: 'response',
      responseType: 'json',
    })
      .toPromise()
      .then(
        (response: HttpResponse<ServerResponse>): Organisation => {
          if (typeof response.body.data !== 'undefined') {
            this.localStorageService.set('default-organisation', response.body.data);
            return response.body.data;
          }

          return null;
        }
      )
      .catch((): null => null);
  }
}
