import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../@fury/shared/scrollbar/scrollbar.module';
import { SurveyComponent } from './survey.component';
import { LoadingOverlayModule } from '../../../@fury/shared/loading-overlay/loading-overlay.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuryAlertBoxModule } from '../../../@fury/shared/alert-box/alert-box.module';
import { LightboxModule } from 'ng-gallery/lightbox';
import { RouterModule } from '@angular/router';
import { FurySharedModule } from '../../../@fury/fury-shared.module';
import { ReportProgressModule } from '../../../@fury/shared/report-progress/report-progress.module';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveyService } from '../../services/reports/survey.service';

@NgModule({
  imports: [
    CommonModule,
    SurveyRoutingModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    LightboxModule,
    RouterModule,
    FurySharedModule,
    ReportProgressModule,
    FormsModule,
  ],
  declarations: [SurveyComponent],
  exports: [SurveyComponent],
  providers: [
    SurveyService,
  ]
})

export class SurveyModule {
}
