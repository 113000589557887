import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@fury/utils/check-router-childs-data';
import { BackdropDirective } from '../../@fury/shared/backdrop/backdrop.directive';
import { environment } from '../../environments/environment';
import { ApiService } from '../services/base/api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('backdrop') backdrop: BackdropDirective;

  scrollDisabled$: Observable<any> = this.router.events.pipe(
    filter<NavigationEnd>((event: NavigationEnd): boolean => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  public badConnection: boolean = false;
  public isStaging: boolean = false;

  constructor(private router: Router,
              public api: ApiService,
  ) {
  }

  ngOnInit(): void {
    if (environment.appVersionAddon === '-staging') {
      this.isStaging = true;
    }
  }

  ngOnDestroy(): void {
  }
}

