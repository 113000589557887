<header *ngIf="(authenticationService.organisation$ | async)">
  <img src="{{ (authenticationService.organisation$ | async)?.logo }}" alt="{{ (authenticationService.organisation$ | async)?.display_name }}">
</header>
<fury-page-layout>
  <fury-page-layout-content @fadeInUp>
    <fury-container>
      <fury-page-layout-content-width wide="middle">
        <h1>Tevredenheidsonderzoek</h1>
        <fury-alert-box class="warn" *ngIf="!response && !isLoading">
          Er is geen melding gevonden.
        </fury-alert-box>
        <fury-alert-box class="warn" *ngIf="response === 'already-received' || response === 'still_open'">
          {{ message }}
        </fury-alert-box>
        <fury-alert-box class="info" *ngIf="response === 'received'">
          {{ message }}
        </fury-alert-box>
        <ng-container *ngIf="response === 'ok'">
          <h2>Hoe tevreden bent u over de afhandeling van uw melding {{ reportNumber }}?</h2>
          <form (ngSubmit)="submit()" [formGroup]="form">
            <div class="tracker_wrap">
              <div class="feedback-container">
                <input type="radio" id="feedback-1" [formControl]="form.controls.survey_response" value="1">
                <label for="feedback-1">
                  <img src="{{ assetsUrl }}/feedback-1.svg" alt="1">
                  <span>Zeer ontvreden</span>
                </label>
              </div>
              <div class="feedback-container">
                <input type="radio" id="feedback-2" [formControl]="form.controls.survey_response" value="2">
                <label for="feedback-2">
                  <img src="{{ assetsUrl }}/feedback-2.svg" alt="2">
                  <span>Ontevreden</span>
                </label>
              </div>
              <div class="feedback-container">
                <input type="radio" id="feedback-3" [formControl]="form.controls.survey_response" value="3">
                <label for="feedback-3">
                  <img src="{{ assetsUrl }}/feedback-3.svg" alt="3">
                  <span>Neutraal</span>
                </label>
              </div>
              <div class="feedback-container">
                <input type="radio" id="feedback-4" [formControl]="form.controls.survey_response" value="4">
                <label for="feedback-4">
                  <img src="{{ assetsUrl }}/feedback-4.svg" alt="4">
                  <span>Tevreden</span>
                </label>
              </div>
              <div class="feedback-container">
                <input type="radio" id="feedback-5" [formControl]="form.controls.survey_response" value="5">
                <label for="feedback-5">
                  <img src="{{ assetsUrl }}/feedback-5.svg" alt="5">
                  <span>Zeer tevreden</span>
                </label>
              </div>
            </div>
            <button color="primary" mat-raised-button>Bevestigen en versturen</button>
          </form>

          <ng-container *ngIf="(authenticationService.organisation$ | async)">
            <h3>Contact opnemen met {{ (authenticationService.organisation$ | async).display_name }}</h3>
            <p>
              Heeft u nog vragen naar aanleiding van uw melding? Neem dan contact op via <a
              href="mailto:{{ (authenticationService.organisation$ | async).email }}">{{ (authenticationService.organisation$ | async).email }}</a>
              of
              telefoonnummer <a
              href="tel:{{ (authenticationService.organisation$ | async).phone_number }}">{{ (authenticationService.organisation$ | async).phone_number }}</a>.
              Bij contact is het altijd handig om uw
              meldnummer {{ reportNumber }} te noemen.
            </p>
          </ng-container>
        </ng-container>
        <div class="white-background" *ngIf="isLoading">
          <fury-loading-overlay [isLoading]="true"></fury-loading-overlay>
        </div>
      </fury-page-layout-content-width>
    </fury-container>
  </fury-page-layout-content>
</fury-page-layout>
