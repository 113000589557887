import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServerResponse } from '../../interfaces/base/server.response';
import { catchError, map } from 'rxjs/operators';
import { Organisation } from '../../interfaces/routering/organisation';
import { Domain } from '../../interfaces/routering/domain';

@Injectable()

export class ApiService {

  public generalError: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(private http: HttpClient,
              private snackbarService: SnackbarService,
  ) {
  }

  getUrl(organisation: Organisation): string {
    if (organisation && organisation.domains) {
      return organisation.domains.map((domain: Domain): string => {
        if (domain.type.name === 'api') {
          return domain.url + (domain.url.endsWith('/') ? '' : '/');
        }
      }).join('');
    }
  }


  get isOnline(): boolean {
    return !!window.navigator.onLine;
  }

  get(
    organisation: Organisation,
    url: string,
  ): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.get(this.getUrl(organisation) + url, {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
    }
  }

  post(organisation: Organisation, url: string, formData?: any): Observable<ServerResponse> {
    return this.http.post(this.getUrl(organisation) + url, (formData ? this.processFormData(formData) : null), {
      observe: 'response',
      responseType: 'json',
    })
      .pipe(
        map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
        catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
      );
  }

  handleResponse(res: HttpResponse<ServerResponse>): ServerResponse {
    this.generalError.next(false);
    if (typeof res.body !== 'undefined') {
      if (typeof res.body.message !== 'undefined') {
        this.snackbarService.success(res.body.message);
      }

      return res.body;
    }
  }

  handleErrorResponse(error: HttpErrorResponse): Observable<ServerResponse> {
    this.generalError.next(true);

    if (typeof error.error !== 'undefined' && typeof error.error.message !== 'undefined') {
      this.snackbarService.error(error.error.message);
    } else {
      this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
    }

    return throwError(error.error);
  }

  processFormData(formData: any, appendPut: boolean = false): FormData | null {
    const postData: FormData = new FormData();
    if (appendPut) {
      postData.append('_method', 'PUT');
    }

    if (typeof formData !== 'undefined') {
      if (formData !== null) {
        Object.keys(formData).forEach((key: string): void => {
          if (Array.isArray(formData[key])) {
            for (let i: number = 0; i < formData[key].length; i++) {
              if (/*formData[key][i] !== false && */formData[key][i] !== 'undefined') {
                if (formData[key][i] instanceof Object) {
                  Object.keys(formData[key][i]).map((d: string): void => {
                    postData.append(key + '[' + i + '][' + d + ']', formData[key][i][d]);
                  });
                } else {
                  postData.append(key + '[' + i + ']', formData[key][i]);
                }
              }
            }
          } else if (formData[key] instanceof Object) {
            Object.keys(formData[key]).forEach((key2: string): void => {
              if (/*formData[key][key2] !== false && */formData[key][key2] !== 'undefined') {
                if (key2.indexOf('[') !== -1) {
                  postData.append(key + key2, formData[key][key2]);
                } else {
                  if (Array.isArray(formData[key][key2])) {
                    formData[key][key2].forEach((d: any): void => {
                      postData.append(key + '[' + key2 + '][]', d);
                    });
                  } else {
                    postData.append(key + '[' + key2 + ']', formData[key][key2]);
                  }
                }
              }
            });
          } else {
            postData.append(key, formData[key]);
          }
        });

        return postData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  notAvailableOffline(): void {
    this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
  }
}
