// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { version } from './version';

export const environment = {
  production: false,
  appVersionAddon: '',
  webappName: 'Straatbeeld Online',
  api_endpoint: 'https://api.straatbeeld.online/',
  sentry: {
    dsn: 'https://dfa39e89875414868ed10659fc3e76b0@o4507735602298880.ingest.de.sentry.io/4508732890480720',
    environment: 'production',
    version: version,
  }
};
